<template>
  <q-dialog v-model="visible">  
    <div class="instruction-card-wrapper">
      <div class="instruction-card">
        <q-card>
          <div>
            <img
              class="preview-media"
              v-if="this.wordData"
              :src="this.wordData.image_url"
            >
          </div>
        </q-card>
        <section class="text-h5">{{sidebarTitle}}</section>
      </div>
      <div class="card-button">
        <q-btn
          outline dense round v-close-popup
          icon="close"
          size="20"
          color="white">
        </q-btn>
      </div>
    </div>
  </q-dialog>
</template>

<script>
import { useDialog } from '@/composables/dialog.js';

export default {
  props: {
    asset: { required: true, type: Object }, wordData: { required: true, type: Object },
  },
  setup: function() {
    const { visible, open, close } = useDialog();
    return { visible, open, close };
  },
  computed: {
    sidebarTitle() {
      // Return "Default Title" if wordData or wordData.word is missing
      return this.wordData && this.wordData.word ? this.wordData.word : "Node";
    },
  },
}
</script>

<style scoped>

.q-dialog__inner > div {
  box-shadow: none;
  overflow: hidden;
  width: 50vw;
  height: 50vw;
}
.q-card {
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px rgb(0 0 0 / 14%), 0 1px 10px rgb(0 0 0 / 12%);;
}

.q-card div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.instruction-card {
  width: 100%;
}
.instruction-card section {
  display: flex;
  justify-content: center;
  color: white;
  font-family: "Open Sans";
  padding-top: 10px;
}
.instruction-card-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-button {
  padding-left: 10px;
}
.instructions-video {
  width: 100% !important;
  height: auto !important;
}
img {
  width: auto;
  max-width: 100%;
  height: auto;
}
</style>
