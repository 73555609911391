<template>
  <div class="custom-sidebar">
    <!-- Enhanced Sidebar Title -->
    <div class="sidebar-title-container">
      <div class="sidebar-title">
        <span>{{ sidebarTitle }}</span>
      </div>
    </div>

    <!-- Image Display Box -->
    <div class="image-box">
      <div class="image-container">
        <img :src="imageUrl" :alt="`Image` " class="image-display"  @click="$refs.fullscreen_preview.open" />
      </div>
    </div>

    <!-- Table Display Below Images -->
    <div class="info-box">
      <div class="info-item">
        <span class="label">MAPPED ON</span>
        <span class="value">{{ tableData[0].value }}</span>
      </div>
      <div class="info-item">
        <span class="label">CONCEPT TYPE</span>
        <span class="value">{{ tableData[1].value }}</span>
      </div>
      <!-- Website Icon Link -->
      <div class="info-item">
        <span class="label">WEBSITE</span>
        <span v-if="tableData[2].value === '-'|| tableData[2].value === '.'">
          <font-awesome-icon :icon="['fas', 'external-link-alt']" class="disabled-icon" />
          <span class="website-link">-</span>
        </span>
        <a v-else :href="tableData[2].value" target="_blank" rel="noopener noreferrer" class="website-icon">
          <font-awesome-icon :icon="['fas', 'external-link-alt']" />
          <span class="website-link">{{ tableData[2].value }}</span>
        </a>
      </div>
    </div>

    <!-- Instructions Section -->
    <!-- Collapsible Instructions Section -->
    <div class="instructions-box">
          <div class="instructions-header" @click="toggleInstructions">
            <font-awesome-icon :icon="['fas', 'info-circle']" class="header-icon" />
            <span>Instructions</span>
            <font-awesome-icon :icon="collapsed ? ['fas', 'chevron-down'] : ['fas', 'chevron-up']" class="toggle-icon" />
          </div>
          <ul v-show="!collapsed" class="instructions-list">
            <li v-for="(instruction, index) in instructions" :key="index" class="instruction-item">
              <font-awesome-icon :icon="['fas', 'circle']" class="bullet-icon" />
              <span>{{ instruction }}</span>
            </li>
          </ul>
        </div>
      </div>
  <full-screen-preview :wordData="wordData" ref="fullscreen_preview">
  </full-screen-preview>
</template>

<script>
import FullScreenPreview from "./FullScreenPreview.vue";

export default {
  components: { FullScreenPreview },
  props: {
    wordData: {
      type: Object,
      required: true,
      default: () => ({ asset_id: null, word_id: null, word: '', type: '-', website:'' })
    },
    background: {
      required: false,
      type: String,
      default: 'img-icon.png',
    },
  },
  data() {
    return {
      instructions: [
        "Explore the semantic network by interacting with the nodes.",
        "Click on nodes to view detailed information.",
        "Use the filter options to narrow down results.",
        "Isolating the selected node(s) will create a sub-network. Click 'Show all data' to reset.",
      ],
      tableData: [],
      imageUrl: '@/assets/brain_on_purple_oval.png',
      collapsed: true
    };
  },
  computed: {
    sidebarTitle() {
      return this.wordData && this.wordData.word ? this.wordData.word : "Node";
    },
  },
  watch: {
    wordData: {
      handler(newWordData) {
        // Check if image_url exists and update accordingly
        this.updateImage(newWordData && newWordData.image_url);
        // Update the table data based on the new wordData values
        this.updateTable();
      },
      immediate: true
    }
  },
  mounted() {
    // Initial fetch for tableData when component mounts
    this.updateTable();
  },
  methods: {
    toggleInstructions() {
      this.collapsed = !this.collapsed;
    },
    updateTable() {
      // Set default values if `wordData.mapped` or `wordData.type` is undefined or null
      const mapped = this.wordData && this.wordData.mapped ? this.wordData.mapped : 'YYYY-MM-DD';
      const type = this.wordData && this.wordData.type ? this.wordData.type : '-';
      const website = this.wordData && this.wordData.website ? this.wordData.website : '-';

      this.tableData = [
        { title: 'Mapped on', value: mapped },
        { title: 'Concept Type', value: type },
        { title: 'Website', value: website },
      ];
    },
    updateImage(image_url) {
      try {
        this.imageUrl = image_url ? image_url : require(`@/assets/${this.background}`);
      } catch {
        this.imageUrl = require(`@/assets/${this.background}`);
      }
    }
  }
};
</script>

<style scoped>
.custom-sidebar {
  position: fixed;
  top: 10%;
  left: 0;
  width: calc(100% - 52%);
  height: calc(100% - 60px);
  padding: 20px;
  z-index: 1;
  overflow: scroll;
  scrollbar-width: thin; /* For Firefox: makes the scrollbar smaller */
  scrollbar-color: #7878e9 transparent; /* Custom scrollbar color */
}

/* Custom scrollbar styling for Webkit browsers (Chrome, Safari) */
.custom-sidebar::-webkit-scrollbar {
  width: 6px; /* Custom scrollbar width */
}

.custom-sidebar::-webkit-scrollbar-track {
  background: transparent; /* Background of the scrollbar track */
}

.custom-sidebar::-webkit-scrollbar-thumb {
  background-color: #7878e9; /* Color of the scrollbar handle */
  border-radius: 4px; /* Round edges of the scrollbar handle */
}

.custom-sidebar::-webkit-scrollbar-thumb:hover {
  background-color: #5a5dbb; /* Darker color on hover */
}

/* Container for the hanging title and lines */
.sidebar-title-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.sidebar-title {
  background-color: transparent;
  border: 2px solid #7878e9;
  color: black;
  padding: 15px 0;
  border-radius: 8px;
  font-size: 1em;
  font-weight: bold;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 95%;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: normal;
}

/* Styled Info Box */
.info-box {
  background: #fff;
  padding: 1vw;
  border: 1px #ccc solid;
  border-radius: 5px;
  padding: 20px;
  align-items: center;
  margin-top: 20px;
  width: 95%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-left: auto;
  margin-right: auto;
}

.info-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
}

.label {
  font-weight: bold;
  color: #333;
}

.value {
  font-size: 1em;
  color: #333;
  text-align: center;
}

.image-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.image-container {
  margin: 10px 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.image-display {
  width: 100%;
  max-width: 200px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  z-index: 10;
}

.image-display:hover {
  transform: scale(1.25);
}

/* Instructions Box */
.instructions-box {
  background-color: #f9fbfd;
  border: 1px solid #7878e9;
  border-radius: 8px;
  padding: 10px;
  margin-top: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 95%;
  transition: max-height 0.3s ease;
  margin-left: auto;
  margin-right: auto;
}

.instructions-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.header-icon {
  color: #7878e9;
  margin-right: 8px;
  font-size: 1.5em;
}

.toggle-icon {
  font-size: 1.2em;
  color: #7878e9;
  transition: transform 0.3s;
  cursor: pointer;
}

.instructions-list {
  list-style: none;
  padding-left: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.instruction-item {
  display: flex;
  align-items: center;
  background-color: #eef3fa;
  border-radius: 5px;
  padding: 10px;
  transition: background 0.3s;
}

.instruction-item:hover {
  background-color: #e2ebf6;
}

.bullet-icon {
  color: #7878e9;
  margin-right: 10px;
  font-size: 1.2em;
}

.instruction-item span {
  font-size: 1em;
  color: #333;
}

/* Add styles for the website icon */
.website-icon {
  color: #7878e9;
  font-size: 1.2em;
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.website-link {
  visibility: hidden;
  opacity: 0;
  background-color: #333;
  color: #fff;
  text-align: left;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.85em;
  position: absolute;
  z-index: 10;
  top: 90%;
  right: 50%;
  /* transform: translate(-50%); Center the tooltip relative to .info-box */
  white-space: nowrap;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s ease;
}

.website-icon:hover .website-link {
  visibility: visible;
  opacity: 1;
}

.disabled-icon {
  color: #ccc; /* Gray color to indicate it's not clickable */
  cursor: default;
}


/* MEDIA QUERIES FOR RESPONSIVE SCALING */

/* Extra Large Screens (1600px and above) */
@media screen and (min-width: 1600px) {
  .custom-sidebar {
    width: 18%;
    padding: 25px;
  }
  .sidebar-title {
    font-size: 1.5em;
    padding: 20px 0;
  }
  .image-display {
    max-width: 240px;
  }
  .info-box {
    padding: 25px;
    font-size: 1.1em;
  }
  .info-item .label {
    font-size: 1em;
  }
  .instructions-box {
    padding: 25px;
  }
  .instructions-header {
    font-size: 1.4em;
  }
  .instruction-item {
    padding: 12px;
  }
  .instruction-item span {
    font-size: 1.1em;
  }
  .header-icon, .bullet-icon {
    font-size: 1.6em;
  }
}

/* Large Screens (1440px to 1599px) */
@media screen and (max-width: 1599px) and (min-width: 1440px) {
  .custom-sidebar {
    width: 20%;
    padding: 20px;
  }
  .sidebar-title {
    font-size: 1.4em;
    padding: 15px 20px;
  }
  .image-display {
    max-width: 200px;
  }
  .info-box {
    padding: 20px;
    font-size: 1em;
  }
  .info-item .label {
    font-size: 0.95em;
  }
  .instructions-box {
    padding: 20px;
  }
  .instructions-header {
    font-size: 1.3em;
  }
  .instruction-item {
    padding: 10px;
  }
  .instruction-item span {
    font-size: 1em;
  }
  .header-icon, .bullet-icon {
    font-size: 1.4em;
  }
}

/* Medium Screens (1280px to 1439px) */
@media screen and (max-width: 1439px) and (min-width: 1280px) {
  .custom-sidebar {
    width: 22%;
    padding: 15px;
  }
  .sidebar-title {
    font-size: 1.3em;
    padding: 12px 18px;
  }
  .image-display {
    max-width: 180px;
  }
  .info-box {
    padding: 15px;
    font-size: 0.95em;
  }
  .info-item .label {
    font-size: 0.9em;
  }
  .instructions-box {
    padding: 15px;
  }
  .instructions-header {
    font-size: 1.2em;
  }
  .instruction-item {
    padding: 8px;
  }
  .instruction-item span {
    font-size: 0.95em;
  }
  .header-icon, .bullet-icon {
    font-size: 1.3em;
  }
}

/* Small Laptops (1024px to 1279px) */
@media screen and (max-width: 1279px) and (min-width: 1024px) {
  .custom-sidebar {
    width: 25%;
    padding: 10px;
  }
  .sidebar-title {
    font-size: 1.2em;
    padding: 10px 15px;
  }
  .image-display {
    max-width: 160px;
  }
  .info-box {
    padding: 10px;
    font-size: 0.9em;
  }
  .info-item .label {
    font-size: 0.85em;
  }
  .instructions-box {
    padding: 12px;
  }
  .instructions-header {
    font-size: 1.1em;
  }
  .instruction-item {
    padding: 8px;
  }
  .instruction-item span {
    font-size: 0.9em;
  }
  .header-icon, .bullet-icon {
    font-size: 1.2em;
  }
}

/* Tablets (768px to 1023px) */
@media screen and (max-width: 1023px) and (min-width: 768px) {
  .custom-sidebar {
    width: 30%;
    padding: 8px;
  }
  .sidebar-title {
    font-size: 1.1em;
    padding: 8px 12px;
  }
  .image-display {
    max-width: 140px;
  }
  .info-box {
    padding: 8px;
    font-size: 0.85em;
  }
  .info-item .label {
    font-size: 0.8em;
  }
  .instructions-box {
    padding: 10px;
  }
  .instructions-header {
    font-size: 1em;
  }
  .instruction-item {
    padding: 6px;
  }
  .instruction-item span {
    font-size: 0.85em;
  }
  .header-icon, .bullet-icon {
    font-size: 1.1em;
  }
}

/* Mobile Devices (Below 768px) */
@media screen and (max-width: 767px) {
  .custom-sidebar {
    width: 100%;
    padding: 5px;
    top: 0;
    left: 0;
  }
  .sidebar-title {
    font-size: 1em;
    padding: 5px 10px;
  }
  .image-display {
    max-width: 120px;
  }
  .info-box {
    padding: 5px;
    font-size: 0.8em;
  }
  .info-item .label {
    font-size: 0.75em;
  }
  .instructions-box {
    padding: 8px;
  }
  .instructions-header {
    font-size: 0.9em;
  }
  .instruction-item {
    padding: 5px;
  }
  .instruction-item span {
    font-size: 0.8em;
  }
  .header-icon, .bullet-icon {
    font-size: 1em;
  }
}
</style>

