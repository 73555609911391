<template>
     <btn class="button_book_help" @click="handleClick"><font-awesome-icon class="icon" icon="question-circle"/></btn>
 </template>

<script>
export default {
    name: "Help",
    methods: {
        handleClick : function () {
             this.$router.push({name: 'Help'})
        },
    },
}
</script>

<style>
.button_book_help {
    padding: 0.5vw 0.5vw;
    border-radius: 2vw;
    font-weight: 600;
    font-size: 2.0vw;
    font-style: normal;
    color: #000000;
    cursor: pointer;
    transition: all 1s;
}

.icon{
        width: 20px;
        color: #ffffff;
    }
</style>
