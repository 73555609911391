<template>
  <div id="app">
    <!-- Custom Sidebar for displaying the node information -->
    <div class="sidebar-wrapper"><Sidebar :wordData="wordData" /></div>
    
    <!-- TensorBoard iframe embedded -->
    <iframe
      src="https://tensorboard.mindspeller.com/#projector"
      id="tensorboard-frame"
    ></iframe>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import Sidebar from './Sidebar.vue';

export default {
  name: 'Dashboard',
  components: {
    Sidebar
  },
  data() {
    return {
      index: null, // Index selected from TensorBoard
      wordData: null, // Stores word data received from API
      label: null,
    };
  },
  methods: {
    // Function to run after iframe loads
    handleIframeMessage(event) {
     if (event.data.type === 'tensorboard-label') {
        const sidebar_selection = event.data.data;
        if (sidebar_selection) {
          this.label = sidebar_selection;
          console.log('Received selection:', this.label);
          this.get_label();
        } else {
          console.log('Sidebar selection is empty');
        }
      } else if (event.data.type === 'clear-selection') {
        console.log('Clear selection received');
        this.wordData = null;
      }
    },
    // async get_word() {
    //   let payload = {
    //     index: this.index 
    //   };
    //   console.log('payload', payload);
    //   try {
    //     // Send POST request to get the word at the specified index
    //     const response = await axios.post('/api/cas/survey_cleaning/projector', payload);
    //     console.log('Response from API:', response);
    //     // Check if response has the data structure we expect
    //     let wordData = response.data;
    //     // Update wordData with the new object
    //     this.wordData = wordData;
    //   } catch (error) {
    //     console.error('Error during API call:', error);
    //   }
    // },
    async get_label() {
      let payload = {
        label: this.label
      };
      console.log('payload', payload);
      try {
        // Send POST request to get the word at the specified index
        const response = await axios.post('/api/cas/survey_cleaning/projector', payload);
        console.log('Response from API:', response);
        // Check if response has the data structure we expect
        let wordData = response.data;
        // Update wordData with the new object
        this.wordData = wordData;
      } catch (error) {
        console.error('Error during API call:', error);
      }
    }

  },
  mounted() {
    // Add event listener for messages from the iframe
    window.addEventListener('message', this.handleIframeMessage);
  },
  beforeDestroy() {
    // Remove event listener when the component is destroyed
    window.removeEventListener('message', this.handleIframeMessage);
  }
};
</script>

<style>
iframe {
  width: 100%;
  height: 100vh;
  border: none;
}
.sidebar-wrapper {
  position: fixed;
  top: 8%;
  left: 0;
  width: 22%; /* Adjust based on your screen size requirements */
  height: calc(100% - 60px);
  z-index: 100;
}
</style>
